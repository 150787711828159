<template>
  <section class="evaluate-section">
    <slot></slot>
		<p class="evaluate-title">心脏风险及原因对策分析</p>
    <div class="data-graph" ref="echart"></div>
    <div class="content-panel">
      <p class="content-subtitle">建议：</p>
      <div v-for="(val, i) in data.suggestions" :key="i" class="content-text">
        <p class="content-text">{{i+1}}. {{val}}</p>
      </div>
    </div>
  </section>
</template>

<script>
import echarts from 'echarts';

export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			firstColumnData: [],
			secondColumnData: [],
			thirdColumnData: [],
		};
	},
	mounted() {
		this.getColumnData();
		const axisLabel = {
			fontSize: 16,
			borderColor: '#000',
			borderWidth: 1,
			borderRadius: 8,
			width: 140,
			height: 34,
			lineHeight: 34,
			align: 'center',
			rich: {},
		};
		const option = {
			parallelAxis: [
				{
					dim: 0,
					type: 'category',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					data: this.firstColumnData,
					axisLabel,
				},
				{
					dim: 1,
					type: 'category',
					name: '∙',
					nameTextStyle: {
						color: '#fff',
					},
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					data: this.secondColumnData,
					axisLabel,
				},
				{
					dim: 3,
					type: 'category',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					data: this.data.otherData,
					axisLabel,
				},
				{
					dim: 2,
					type: 'category',
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					data: this.thirdColumnData,
					axisLabel,
				},
			],
			series: {
				type: 'parallel',
				lineStyle: {
					width: 2,
					color: '#558ED5',
				},
				silent: true,
				data: this.data.lineData,
			},
		};
		echarts.init(this.$refs.echart).setOption(option);
	},
	methods: {
		getColumnData() {
			const orderedData = [];
			this.data.suggestions.forEach((val) => {
				this.data.lineData.forEach((item) => {
					if (item[2] === val) {
						orderedData.push(item);
					}
				});
			});
			orderedData.reverse().forEach((val) => {
				if (!this.firstColumnData.includes(val[0])) this.firstColumnData.push(val[0]);
				if (!this.secondColumnData.includes(val[1])) this.secondColumnData.push(val[1]);
				if (!this.thirdColumnData.includes(val[2])) this.thirdColumnData.push(val[2]);
			});
		},
	},
};
</script>

<style lang="less" scoped>
.data-graph {
  height: 770px;
  margin-bottom: 25px;
}
.content-panel {
  min-height: 140px;
  .content-subtitle {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
  }
  .content-text {
    margin: 5px 0;
  }
}
</style>
