<template>
  <section class="evaluate-section">
    <slot></slot>
		<p class="evaluate-title">风险提示总结</p>
    <Form class="need-remove"
        :label-width="85"
       >
      <Form-item label="选择问题：">
        <Select v-model="selectedProblems" multiple style="width:350px">
          <Option v-for="item in data.wtlb" :value="item" :key="item">{{ item }}</Option>
        </Select>
        <Button type="primary" @click="getDangerSolutions">确定</Button>
      </Form-item>
    </Form>
    <div class="content-panel">
      <p class="content-subtitle">主要问题：</p>
      <p v-for="(val, i) in problems" :key="i" class="content-text">{{i+1}}. {{val}}</p>
    </div>
    <div class="content-panel">
      <p class="content-subtitle">未来风险：</p>
      <div v-for="(val, i) in dangerous.wlfx" :key="i" class="content-text">
        <p>{{i+1}}. {{selectedProblems[i]}}</p>
        <div v-html="replaceBreak(val)"></div>
      </div>
    </div>
    <div class="content-panel">
      <p class="content-subtitle">风险规避：</p>
      <p class="content-text" v-html="replaceBreak(dangerous.fxgb)"></p>
    </div>
    <Form class="need-remove"
        :label-width="110"
       >
      <Form-item label="选择指导建议：">
        <Select v-model="selectedSuggestions" multiple style="width:350px">
          <Option v-for="item in data.zdjy" :value="item" :key="item">{{ item }}</Option>
        </Select>
        <Button type="primary" @click="getSuggestions">确定</Button>
      </Form-item>
    </Form>
    <div class="content-panel">
      <p class="content-subtitle">指导建议：</p>
      <p v-for="(val, i) in suggestions" :key="i" class="content-text">{{i+1}}. {{val}}</p>
    </div>
  </section>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			selectedProblems: [],
			problems: [],
			dangerous: {},
			selectedSuggestions: [],
			suggestions: [],
		};
	},
	methods: {
		getDangerSolutions() {
			if (this.selectedProblems.length > 0) {
				MemberService.getDangerSolution(JSON.stringify(this.selectedProblems)).then((data) => {
					this.problems = this.selectedProblems;
					this.dangerous = data;
				});
			} else {
				this.$Message.error('请先选择问题');
			}
		},
		getSuggestions() {
			if (this.selectedSuggestions.length > 0) {
				this.suggestions = this.selectedSuggestions;
			} else {
				this.$Message.error('请选择知道建议');
			}
		},
		replaceBreak(val) {
			if (val) return val.replace(/\r/g, '<br>');
			else return '';
		},
	},
};
</script>

<style lang="less" scoped>
.content-panel {
  min-height: 140px;
  .content-subtitle {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 700;
  }
  .content-text {
    margin: 10px 0;
  }
}
</style>
