<template>
  <section class="evaluate-section">
    <slot></slot>
		<p class="evaluate-title">动态心电正常与异常数据分析</p>
    <div class="content-table-panel">
      <table class="content-table">
        <tr>
          <th>正常数据</th>
          <th>具体数值</th>
          <th>标准范围</th>
        </tr>
      </table>
      <table class="content-table">
        <tr v-for="(val, index) in data.zc_sj" :key="index">
          <td>{{val.mc}}</td>
          <td>{{val.sz}}</td>
          <td>{{val.bz}}</td>
        </tr>
      </table>
    </div>
    <div class="content-table-panel">
      <table class="content-table">
        <tr>
          <th>异常数据</th>
          <th>具体数值</th>
          <th>标准范围</th>
          <th>对应等级</th>
        </tr>
      </table>
      <table class="content-table">
        <tr v-for="(val, index) in data.yc_sj" :key="index">
          <td>{{val.mc}}</td>
          <td>{{val.sz}}</td>
          <td>{{val.bz}}</td>
          <td>{{val.dj}}</td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>

<style lang="less" scoped>
.content-table-panel {
  margin-bottom: 25px;
}
.content-table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0;
  font-size: 16px;
  text-align: center;
  &:last-child {
    border: solid 1px #000;
    border-radius: 8px;
    padding: 6px;
  }
  td {
    padding: 6px;
  }
  th {
    border: solid 1px #000;
    border-radius: 8px;
    padding: 6px;
    font-weight: normal;
  }
}
</style>
