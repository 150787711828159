<template>
  <div class="evaluate-header-container">
    <p class="evaluate-header-title">适生标准健康研究院</p>
    <p class="evaluate-header-subtitle">健康缺失及风险预警</p>
    <div class="evaluate-info">
      <div class="evaluate-info_left">
        病例号：{{data.blh}}
      </div>
      <div class="evaluate-info_right">
        测试日期：{{data.csrq}}
      </div>
    </div>
    <div class="user-info">
      <div class="user-info-field">姓名：<span style="font-size: 28px">{{data.xm}}</span></div>
      <div class="user-info-field">性别：{{data.xb}}</div>
      <div class="user-info-field">年龄：{{data.nl}}</div>
      <div class="user-info-field">科室：{{data.ks}}</div>
      <div class="user-info-field">起搏器：{{data.qbq}}</div>
      <div class="user-info-field">记录编号：{{data.jlbh}}</div>
      <div class="user-info-field">检测师：{{data.czys}}</div>
    </div>
  </div>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>

<style lang="less" scoped>
.evaluate-header-container {
  .evaluate-header-title {
    font-weight: 700;
    font-size: 20px;
    padding: 15px 0 5px;
    text-align: center;
  }
  .evaluate-header-subtitle {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 20px;
    text-align: center;
  }
  .evaluate-info {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .user-info {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 16px;
    &-field {
      display: inline-block;
      width: 24.5%;
      padding: 5px 0;
      &:last-child {
        width: 49%;
      }
    }
  }
}
</style>
