<template>
  <section class="evaluate-section">
    <slot></slot>
		<p class="evaluate-title">动态心电分析统计</p>
    <div class="content-panel">
      <p class="content-title--center">概述</p>
      <div class="content-body">
        <div class="content-half">
          <div class="content-row">
            <span>分析时间(hh:mm)</span><span>{{data.fxsj.substring(0, 5)}}</span>
          </div>
          <div class="content-row">
            <span>总心搏数</span><span>{{data.xbzs}}</span>
          </div>
          <div class="content-row">
            <span>起搏心搏</span><span>{{data.qbxb}}</span>
          </div>
          <div class="content-row">
            <span>室性心搏</span><span>{{data.sxxb}}</span>
          </div>
        </div>
        <div class="content-half">
          <div class="content-row">
            <span>室上性心搏</span><span>{{data.ssxxb}}</span>
          </div>
          <div class="content-row">
            <span>停搏大于 2 秒</span><span>{{data.tblm}}</span>
          </div>
          <div class="content-row">
            <span>最长停搏</span><span>{{data.zctb}}</span>
          </div> 
        </div>
      </div>
    </div>
    <div class="content-triple-cols">
      <div class="content-panel">
        <p class="content-title--center">室性节律</p>
        <div class="content-row">
          <span>单个</span><span>{{data.dg}}</span>
        </div>
        <div class="content-row">
          <span>成对</span><span>{{data.cd}}</span>
        </div>
        <div class="content-row">
          <span>二联律</span><span>{{data.elv}}</span>
        </div>
        <div class="content-row">
          <span>三联律</span><span>{{data.slv}}</span>
        </div>
        <div class="content-row">
          <span>室性连发</span><span>{{data.sxxdgs}}</span>
        </div>
        <div class="content-row">
          <span>最长室性连发</span><span>{{data.zcss}}</span>
        </div>
        <div class="content-row">
          <span>室性连发最快心率</span><span>{{data.zkss}}</span>
        </div>
        <div class="content-row">
          <span>室性连发最慢心率</span><span>{{data.zmss}}</span>
        </div>
      </div>
      <div class="content-panel">
        <p class="content-title--center">室上性节律</p>
        <div class="content-row">
          <span>单个</span><span>{{data.dg_s}}</span>
        </div>
        <div class="content-row">
          <span>成对</span><span>{{data.cd_s}}</span>
        </div>
        <div class="content-row">
          <span>二联律</span><span>{{data.elv_s}}</span>
        </div>
        <div class="content-row">
          <span>三联律</span><span>{{data.slv_s}}</span>
        </div>
        <div class="content-row">
          <span>室上性连发</span><span>{{data.sxxdgs_s}}</span>
        </div>
        <div class="content-row">
          <span>最长室上性连发</span><span>{{data.zcss_s}}</span>
        </div>
        <div class="content-row">
          <span>最快室上性连发</span><span>{{data.zkss_s}}</span>
        </div>
        <div class="content-row">
          <span>房早未下传</span><span>{{data.zmss_s}}</span>
        </div>
      </div>
      <div class="content-panel">
        <p class="content-title--center">心率变异性</p>
        <div class="content-row">
          <span>SDNN</span><span>{{data.SDNN}}</span>
        </div>
        <div class="content-row">
          <span>SDNN index</span><span>{{data.SDNNindex}}</span>
        </div>
        <div class="content-row">
          <span>rMSSD</span><span>{{data.rMSSD}}</span>
        </div>
        <div class="content-row">
          <span>pNN50</span><span>{{data.pNN50}}</span>
        </div>
        <div class="content-row">
          <span>三角指数</span><span>{{data.sjzs}}</span>
        </div>
        <div class="content-row">
          <span>HF(norm)</span><span>{{data.HF}}</span>
        </div>
        <div class="content-row">
          <span>LF(norm)</span><span>{{data.LF}}</span>
        </div>
        <div class="content-row">
          <span>VLF</span><span>{{data.VLF}}</span>
        </div>
      </div>
    </div>
    <div class="content-double-cols">
      <div class="content-panel" style="width: 49%;">
        <p class="content-title--center">心率</p>
        <div class="content-row">
          <span>平均心率</span><span>{{data.pjxl}}</span>
        </div>
        <div class="content-row">
          <span>最慢心率</span><span>{{data.zmxl}}</span>
        </div>
        <div class="content-row">
          <span>最快心率</span><span>{{data.zkxl}}</span>
        </div>
        <div class="content-row">
          <span>分钟计最慢心率</span><span>{{data.fzjzmxl}}</span>
        </div>
        <div class="content-row">
          <span>分钟计最快心率</span><span>{{data.fzjzkxl}}</span>
        </div>
        <div class="content-row">
          <span>心动过速心搏(>100bpm)</span><span>{{data.xdksxb}}</span>
        </div>
        <div class="content-row">
          <span>心动过缓心搏(&lt;50bpm)</span><span>{{data.xdghxb}}</span>
        </div>
        <div class="content-row">
          <span>最长RR间期</span><span>{{data.zcrrjg}}</span>
        </div>
      </div>
      <div style="width: 49%;">
        <div class="content-panel">
          <p class="content-title--center">逸搏</p>
          <div class="content-row">
            <span>室性逸搏</span><span>{{data.sxyb}}</span>
          </div>
          <div class="content-row">
            <span>房性逸搏</span><span>{{data.fxyb}}</span>
          </div>
          <div class="content-row">
            <span>交界性逸搏</span><span>{{data.jjxyb}}</span>
          </div>
        </div>
        <div class="content-panel" style="height: 125px;">
          <p class="content-title--center">房颤/房扑</p>
          <div class="content-row">
            <span>房颤/房扑心搏数</span><span>{{data.fpxbs}}</span>
          </div>
          <div class="content-row">
            <span>房颤/房扑</span><span>{{data.fp}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-panel">
      <p class="content-title--center">CH1</p>
      <div class="content-row">
        <span>最大ST压低</span><span>{{data.zdstyd}}</span>
      </div>
      <div class="content-row">
        <span>最大ST抬高</span><span>{{data.zdsttg}}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	created() {},
	methods: {},
};
</script>

<style lang="less" scoped>
.content-triple-cols {
  display: flex;
  justify-content: space-between;
  .content-panel {
    width: 32%;
  }
}
.content-double-cols {
  display: flex;
  justify-content: space-between;
}
</style>
