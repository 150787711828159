<template>
  <section class="evaluate-section">
    <slot></slot>
    <p class="evaluate-title">动态心电总结分析</p>
    <table class="content-table">
      <tr>
        <th>综合评分</th>
        <th>健康等级</th>
        <th>健康指南</th>
      </tr>
      <tr>
        <td style="font-size:48px;font-weight:700;">{{data.zhpf}}</td>
        <td style="font-size:48px;font-weight:700;">{{data.jkdj}}</td>
        <td>{{data.jkzn}}</td>
      </tr>
    </table>
    <div class="data-graph-panel">
      <div class="data-graph" ref="echart"></div>
      <div :class="['data-label', mapClass(i)]" v-for="(val, i) in result" :key="i">
        <div :style="{color: colors[i]}">
          <p class="data-title">{{val.lb}}</p>
          <p class="data-subtitle">({{val.zb}}%)</p>
        </div>
        <div class="content-panel">
          <span>{{val.yc.join('，')}}</span>
        </div>
        <div class="content-panel">
          {{val.cs}}
        </div>
      </div>
      <ul class="data-list">
        <li v-for="(val, i) in result" :key="i" :style="{color: colors[i]}">{{val.zb}}%</li>
      </ul>
    </div>
  </section>
</template>

<script>
import echarts from 'echarts';

export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			result: [],
			colors: ['#953735', '#e46c0a', '#558ed5', '#77933c'],
		};
	},
	mounted() {
		const { type4 } = this.data;
		this.result = type4.sort((a, b) => b.zb - a.zb);
		const option = {
			tooltip: {
				show: false,
			},
			legend: {
				show: false,
			},
			series: [
				{
					name: '在建30%',
					type: 'pie',
					radius: '10%', // 环的位置
					label: {
						show: false,
					},
					hoverAnimation: false,
					data: [
						{
							value: 100, // 需要显示的数据
							itemStyle: {
								normal: {
									color: '#777',
								},
							},
						},
					],
				},
				{
					name: '30%',
					type: 'pie',
					radius: ['10%', '20%'], // 环的位置
					label: {
						show: false,
					},
					hoverAnimation: false,
					data: [
						{
							value: this.result[0].zb, // 需要显示的数据
							itemStyle: {
								normal: {
									color: '#953735',
								},
							},
						},
						{
							value: 100 - this.result[0].zb, // 不需要显示的数据，颜色设置成和背景一样
							itemStyle: {
								normal: {
									color: 'transparent',
								},
							},
						},
					],
				},
				{
					name: '30%',
					type: 'pie',
					radius: ['20%', '30%'], // 环的位置
					label: {
						show: false,
					},
					hoverAnimation: false,
					data: [
						{
							value: this.result[1].zb, // 需要显示的数据
							itemStyle: {
								normal: {
									color: '#e46c0a',
								},
							},
						},
						{
							value: 100 - this.result[1].zb, // 不需要显示的数据，颜色设置成和背景一样
							itemStyle: {
								normal: {
									color: 'transparent',
								},
							},
						},
					],
				},
				{
					name: '45%',
					type: 'pie',
					radius: ['30%', '40%'],
					label: {
						show: false,
					},
					hoverAnimation: false,
					data: [
						{
							value: this.result[2].zb,
							itemStyle: {
								normal: {
									color: '#558ed5',
								},
							},
						},
						{
							value: 100 - this.result[2].zb,
							itemStyle: {
								normal: {
									color: 'transparent',
								},
							},
						},
					],
				},
				{
					name: '70%',
					type: 'pie',
					radius: ['40%', '50%'],
					label: {
						show: false,
					},
					hoverAnimation: false,
					data: [
						{
							value: this.result[3].zb,
							itemStyle: {
								normal: {
									color: '#77933c',
								},
							},
						},
						{
							value: 100 - this.result[3].zb,
							itemStyle: {
								normal: {
									color: 'transparent',
								},
							},
						},
					],
				},
			],
		};
		echarts.init(this.$refs.echart).setOption(option);
	},
	methods: {
		mapClass(index) {
			switch (index) {
				case 0:
					return 'data-top-left';
				case 1:
					return 'data-bottom-left';
				case 2:
					return 'data-bottom-right';
				case 3:
					return 'data-top-right';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content-table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  border-spacing: 0;
  font-size: 16px;
  th,
  td {
    border: solid 1px #000;
    border-radius: 8px;
    padding: 6px;
    font-weight: normal;
  }
  td {
    height: 150px;
    text-align: center;
  }
}
.data-graph-panel {
  position: relative;
  .data-graph {
    height: 600px;
    margin-top: 100px;
  }
  .data-label {
    font-size: 16px;
    width: 200px;
    .content-panel {
      margin: 10px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      min-height: 50px;
    }
  }
  .data-top-left {
    position: absolute;
    top: 10px;
    left: 50px;
  }
  .data-top-right {
    position: absolute;
    top: 10px;
    right: 50px;
  }
  .data-bottom-left {
    position: absolute;
    bottom: 10px;
    left: 50px;
  }
  .data-bottom-right {
    position: absolute;
    bottom: 10px;
    right: 50px;
  }
  .data-title {
    font-size: 18px;
    font-weight: 700;
  }
  .data-list {
    position: absolute;
    left: 410px;
    top: 152px;
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
    li {
      font-size: 18px;
      font-weight: 700;
      height: 30px;
      text-shadow: 0 0 2px #fff;
    }
  }
}
</style>
