<template>
  <div class="evaluate-container" v-if="loaded">
		<div class="evaluate-content-container">
			<evaluate-one :data="firstData"><evaluate-header :data="headerData"></evaluate-header></evaluate-one>
			<evaluate-two :data="secondData"><evaluate-header :data="headerData"></evaluate-header></evaluate-two>
			<evaluate-three :data="thirdData"><evaluate-header :data="headerData"></evaluate-header></evaluate-three>
			<evaluate-four :data="fourthData"><evaluate-header :data="headerData"></evaluate-header></evaluate-four>
			<evaluate-five :data="fifthData"><evaluate-header :data="headerData"></evaluate-header></evaluate-five>
		</div>
		<div class="symbol-box tac">
			<Button type="success" size="large" @click="showModal = true" ref="btn">导出心电预警评估报告</Button>
			<Button type="primary" size="large" @click="handleSubmit">手动提交报告</Button>
		</div>
		<Modal
			v-model="showModal"
			:loading="loading"
			@on-ok="handleExport"
			class-name="vertical-center-modal"
			:mask-closable="false">
			<p style="text-align:center;padding:15px;">确定导出心电预警评估报告吗?</p>
			<p style="text-align:center;padding:15px;">此操作同时会自动上传该报告。</p>
		</Modal>
  </div>
</template>

<script>
import JsPDF from 'jspdf';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import MemberService from '@/services/memberService';
import EvaluateHeader from './evaluate-header';
import EvaluateOne from './evaluate-1';
import EvaluateTwo from './evaluate-2';
import EvaluateThree from './evaluate-3';
import EvaluateFour from './evaluate-4';
import EvaluateFive from './evaluate-5';

export default {
	components: {
		EvaluateHeader,
		EvaluateOne,
		EvaluateTwo,
		EvaluateThree,
		EvaluateFour,
		EvaluateFive,
	},
	data() {
		return {
			loaded: false,
			headerData: {},
			firstData: {},
			secondData: {},
			thirdData: {},
			fourthData: {},
			fifthData: {},
			loading: true,
			showModal: false,
		};
	},
	created() {
		const memberId = this.$route.params.member_id;
		const recordId = this.$route.params.record_id;
		let params;
		if (recordId) params = { jl_id: recordId };
		else params = { member_id: memberId };
		MemberService.getECGEvaluate(params).then((data) => {
			this.loaded = true;
			this.headerData = data.jcxx;
			this.firstData = data.zjfx;
			this.secondData = data.zc_yc;
			this.thirdData = data.fxtj;
			this.fourthData = {
				lineData: data.page4_lx_data,
				otherData: data.page4_jb_data,
				suggestions: data.page4_jy_data,
			};
			this.fifthData = {
				zdjy: data.zdjy,
				wtlb: data.wtlb,
				wtxq: data.fxtszj,
			};
		});
	},
	methods: {
		handleExport() {
			const self = this;
			const printTarget = document.querySelectorAll('.evaluate-section');
			if (printTarget.length > 0) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
				setTimeout(() => {
					const needRemove = document.querySelectorAll('.evaluate-section .need-remove');
					needRemove.forEach((ele) => {
						ele.style.display = 'none';
					});
					const pdf = new JsPDF({ compress: true, unit: 'pt' });
					for (let i = 0; i < printTarget.length; i++) {
						html2canvas(printTarget[i]).then(function (canvas) {
							const contentWidth = canvas.width;
							const contentHeight = canvas.height;
							const imgWidth = 555.28;
							const pageMargin = 20;
							const pageData = canvas.toDataURL('image/png', 1.0);

							if (i !== 0) pdf.addPage();

							const imgHeight = (imgWidth / contentWidth) * contentHeight;
							pdf.addImage(pageData, 'png', pageMargin, 0, imgWidth, imgHeight);

							if (i === printTarget.length - 1) {
								const time = dayjs().format('YYYYMMDD');
								const fileName = `${self.headerData.xm}${time}心电预警评估报告.pdf`;
								const formData = new FormData();
								formData.append('photo', pdf.output('blob'));
								formData.append('check', 1);
								formData.append('name', fileName);
								MemberService.uploadReport(formData).then((data) => {
									MemberService.addReport({
										member_id: self.$route.params.member_id,
										type: 1,
										bgzl: 34,
										check_date: data.check_date,
										photo_id: data.id,
										bgms: '出具报告自动上传',
										obj_id: 'XD-' + self.$route.params.record_id,
									})
										.then(() => {
											self.$Message.success('转存成功');
										})
										.catch(() => {
											self.$Message.success('转存失败，请手动上传');
										});
								});

								pdf.save(fileName);
								self.showModal = false;
							}
						});
					}
				}, 667);
			} else {
				this.$Message.warning('无法生成报告，请稍后重试');
			}
		},
		handleSubmit() {
			this.$router.push({ name: 'memberReportAdd' });
		},
	},
};
</script>

<style lang="less">
.evaluate-container {
	background-color: #fff;
	padding-top: 20px;
	.evaluate-section {
		width: 920px;
		height: 1380px;
		margin: 0 auto;
		.evaluate-title {
			font-size: 18px;
			font-weight: 700;
			width: 450px;
			border: 1px solid #000;
			border-radius: 8px;
			text-align: center;
			margin: 25px auto;
			padding: 5px 0;
		}
		.content-panel {
			border: 1px solid #000;
			border-radius: 8px;
			padding: 0 10px;
			margin-bottom: 20px;
			font-size: 16px;
			.content-title--center {
				text-align: center;
				padding: 10px 0;
				font-weight: 700;
			}
			.content-body {
				display: flex;
				justify-content: space-between;
				.content-half {
					width: 45%;
				}
			}
			.content-row {
				display: flex;
				justify-content: space-between;
				margin-bottom: 5px;
			}
		}
	}
	.symbol-box {
		padding-bottom: 20px;
	}
}
</style>
